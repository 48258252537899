import React from 'react'
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Tag,
} from '@chakra-ui/react'
import { useField } from 'remix-validated-form'

type Props = {
  name: string
  required?: boolean
  label: string
  children: React.ReactNode
}

export const FormControlWrapper = (props: Props) => {
  const { required, label, name, children } = props
  const { error } = useField(name)

  const requiredBatch = required ? (
    <Tag lineHeight="none" size="sm" variant="alert" whiteSpace="nowrap">
      必須
    </Tag>
  ) : null

  return (
    <FormControl isInvalid={!!error}>
      <Flex flexDirection="column" gap={2} w="full">
        <Box alignItems="center" display="flex" flexShrink={0} mr={6} w="160px">
          <FormLabel
            color="text.primary"
            fontSize="sm"
            htmlFor={name}
            margin={0}
          >
            {label}
          </FormLabel>
          {requiredBatch && (
            <Flex flexShrink={0} lineHeight="none" ml={2}>
              {requiredBatch}
            </Flex>
          )}
        </Box>
        <Box w="full">{children}</Box>
      </Flex>
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  )
}
